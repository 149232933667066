import React from "react";
import { useEffect, useState } from "react"
import { useFormHandler } from "../scripts/hooks/useFormHandler.tsx";
import readXlsxFile from 'read-excel-file'
import { sendMails } from "../scripts/dataHandler.ts";

interface Props {
    token: string
}

function SenderPage({token}: Props) {

    let [emails, setEmails] = useState([])
    let [subject, setSubject] = useState("")
    let [html, setHtml] = useState(`
        Good morning/afternoon/evening, 
        <br><br>
        I am writing to you on behalf of the AlejMUN 25 organizing team to extend an invitation to next year’s conference. AlejMUN is a Model United Nations event organized by a group of students from Gymnázium nad Alejí, alongside other enthusiastic individuals from across the Czech Republic. AlejMUN 25 will take place from March 14th to 17th, 2025, at Gymnázium nad Alejí, Prague.
        <br><br>
        Now in its second year, we aim to build upon the success of last year’s conference and continue to grow our legacy. This year’s event will feature six diverse committees, with topics ranging from arms embargo to the Trium Virorum as our historical committee topic. Our primary goal is to provide delegates with an enriching and enjoyable learning experience, and we have carefully selected topics to ensure they are both engaging and thought-provoking.
        <br><br>
        For more information about AlejMUN 25, please visit our website, where you will also be able to apply as a delegation or single delegate until December 31 when the registrations end
        <br><br>
        If you have any questions, feel free to reach out to me via email, I’ll be happy to help you.
        <br><br>

        Warm regards,
        <br>
        <strong>
        (your name)
        <br>
        (your position) at AlejMUN 2025
        </strong>
        <br>

        <img src="https://static.smoulasoutez.cz/alejlogo.png"/>
        `)

    const change = (e) => {
        setHtml(e.target.value)
    }

    const d = (arr,val) => {
        console.log(arr)
        return [...arr,val]
    }

    const excelChange = (e) => {
        const elem = e.target
        readXlsxFile(elem.files[0]).then((rows) => {
            rows.splice(1).forEach(r => {
                setEmails(arr => d(arr,r[2]))
            })
            console.log(emails)
          })
    }

    const mapEmails = (mails) => {
        return mails.map(m => m && <div className="row">
            <input
          type="checkbox"
          className="mailCheck"
          id={m} /*onChange={(e) => {handleTeamSelect(e)}}*/
        />
        <p>{m}</p>
        </div>)
    }

    const checkAll = (e) => {
        let boxes = Array.from(document.getElementsByClassName("mailCheck")) as HTMLInputElement[];
        boxes.forEach((box) => {
          box.checked = e.target.checked;
        });
      };
    
      const getSelected = () => {
        let boxes = Array.from(document.getElementsByClassName("mailCheck")) as HTMLInputElement[];
        let res = boxes.filter((b) => b.checked).map((b) => b.id);
        return res;
      };

      const subjectChange = (e) => {
        setSubject(e.target.value)
      }

      let [err, setErr] = useState("")

    return ( 
        <>
        <h2>data (pls upload the excel file with emails AS .XLSX)</h2>
        <p>always uses the values from the 3rd column as email</p>
        <input type="file" id="excel" onChange={excelChange}/>

        {emails.length && <div>

        <h2>emails</h2>
        <div className="row">
            <input type="checkbox" onClick={checkAll}/>
            <p>check all</p>
        </div>
        <br/>
        {mapEmails(emails)}

            </div>}

        <h2>subject</h2>
        <input type="text" onChange={subjectChange}/>

        <h2>content (accepts html)</h2>
        <textarea onChange={change} id="text" cols="50" rows="30">{html}</textarea>

        <h2>preview</h2>
        <div dangerouslySetInnerHTML={{__html:html}} style={{"width": "70vw", "border": "2px solid black", "padding": "1vw 1vh"}}/>

        <br/>

        <button onClick={() => {sendMails(token,getSelected(),subject,html,setErr)}}>send</button>
        <p>{err}</p>
        </>
     );
}

export default SenderPage;