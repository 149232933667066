import React from 'react';
import { useState } from "react";
import './App.css';
import Cookies from 'universal-cookie';
import SenderPage from './pages/SenderPage.tsx';
import LoginPage from './pages/LoginPage.tsx';

function App() {

  let cookies = new Cookies()

  let [token, setToken] = useState<string>(cookies.get("token") || "")

  const setCookie = (token: string) => {
    cookies.set("token", token, { path: "/", maxAge: 16200 })
    setToken(token)
  }

  return (
    <div className="container">

        <h1>alejmun mail sender</h1>

        {token ? <SenderPage token={token}/> : <LoginPage setCookie={setCookie}/>}

    </div>
  );
}

export default App;
