import Cookies from "universal-cookie";

export type StrIndexedObj = {[key: string]: any}

const defaultUrl = "https://ashleyy.dev"

// Basic req functions
const sendReq = (opts: Object, url: string, endpoint: string, errMsgSetter?: Function) => {
  return fetch(url + endpoint, opts)
    .then(async (res) => {
      let temp = await res.json();
      temp.status = res.status;
      return temp;
    })
    .catch((err) => {
      console.log(err);
      if (errMsgSetter) {errMsgSetter("Něco se pokazilo")}
    });
};

export const sendGetReq = async (endpoint: string, errMsgSetter?: Function, url: string = defaultUrl) => {
  const opts = {
    method: "GET",
  };
  return await sendReq(opts, url, endpoint, errMsgSetter);
};

export const sendPostReq = async (endpoint: string, body: StrIndexedObj, errMsgSetter?: Function, url: string = defaultUrl) => {
  const opts = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    }
  };
  return await sendReq(opts, url, endpoint, errMsgSetter);
};

// abstracct reqs uuuhhh
export const loginReq = async (data: any, setter: Function, errMsgSetter?: Function) => {
    console.log(data)
    let res = await sendPostReq("/alejmun/login", data)
  
    if (res) {
      if (res.status !== 200) {
        if (errMsgSetter) {errMsgSetter(res.msg)}
        return
      }
  
      setter(res.token)
    }
    return
  }

export const sendMails = async (token: string, mails: string[], subject: string, html: string, errMsgSetter?: Function) => {
  const body = {
    token: token,
    emails: mails,
    subject: subject,
    html: html
  }

  let res = await sendPostReq("/alejmun/sendMail", body)
  if (res) {
    if (res.status !== 200) {
      if (errMsgSetter) {errMsgSetter(res.msg)}
      if (res.status == 403) {
        let cookies = new Cookies()
        cookies.set("token","")
        window.location.reload()
        return
      }
      return
    }
    if (errMsgSetter) {errMsgSetter("emails sent :) (hopefully)")}
  }
  return
}
  