import React from 'react';
import { useState } from "react";
import { useFormHandler } from '../scripts/hooks/useFormHandler.tsx';
import { loginReq } from '../scripts/dataHandler.ts';

interface Props {
    setCookie: Function
}

function LoginPage({setCookie}: Props) {

    let login = () => {}
    let [err,setErr] = useState("")

    let {formData, formChange} = useFormHandler({
        pass: ""
    }, login, true)

    login = () => {
        loginReq(formData, setCookie, setErr)
    }

    return ( 
        <>

            <h1>Login</h1>

            <input id="pass" onChange={formChange} type="text"/>
            <button onClick={login}>odeslat</button>

            <h2>{err}</h2>

        </>
     );
}

export default LoginPage;